import React, { Component, Fragment, useState } from "react"
import { useQuery } from "@apollo/client"

import MenuNav, { MobileMenu } from "../Navigation"
import logoImage from "../../resources/images/logo-circle.png"
import Theme from "../../Themes/theme"
import { HeaderWrapper, Img } from "./style"
import { LinkForBtn, Button } from "../Universal/style"
import { CURRENT_YEAR_SEASON_DATES } from "./queries"

const Header = () => {
  // For mobile menu
  const [showMenu, setMenuStatus] = useState(false)
  // For the season date query
  const { loading, error, data } = useQuery(CURRENT_YEAR_SEASON_DATES)

  return (
    <Fragment>
      <Theme>
        <HeaderWrapper>
          <div className="header-inner">
            <div className="nav-wrapper">
              <div className="nav-inner">
                <MenuNav />

                {!showMenu ? (
                  <div className="hamburger-wrapper">
                    <div id="hamburger-icon" onClick={() => setMenuStatus(true)}>
                      <span className="line-1"></span>
                      <span className="line-2"></span>
                      <span className="line-3"></span>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <MobileMenu setMenuStatus={setMenuStatus} />
                  </Fragment>
                )}

                <LinkForBtn href={process.env.APP_URL_LOGIN} target="_blank" rel="noopener noreferrer">
                  <Button>Login</Button>
                </LinkForBtn>
              </div>
            </div>
            <div className="headermiddle-wrapper">
              <div className="header-left">
                <div className="logotext-wrapper">
                  <Img alt="site-logo" src={logoImage} />
                  <div className="businesstext-wrapper">
                    <h1>Flexi Tennis Leagues</h1>
                    <div className="tagline-wrapper">
                      <p>Play Anytime</p>
                      <p>Anywhere</p>
                      <p>At Any Skill Level</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-right">
                <div className="notice-wrapper">
                  <div className="notice-inner">
                    <div className="notice-body-wrap">
                      <div className="notice-body-inner">
                        {/* For the season dates -> taken from the server of the FTL App */}
                        {loading && <p>Loading..</p>}
                        {error && <p>Current Not Available..</p>}
                        {data && <SeasonDates data={data} type={"header"} />}

                        <div className="button-wrapper">
                          <LinkForBtn href={process.env.APP_URL} target="_blank" rel="noopener noreferrer">
                            <Button>Sign Up Now!</Button>
                          </LinkForBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-bottom"></div>
          </div>
        </HeaderWrapper>
      </Theme>
    </Fragment>
  )
}

class SeasonDates extends Component {
  constructor() {
    super()
    this.state = {
      nextSeasonText: "",
      nextSeasonOpenDate: null,
      nextSeasonCloseDate: null,
    }
  }

  componentDidMount() {
    // Dates from the Database -> FTL App
    let { previousSpring, summer, autumn, winter, spring, nextSummer } = this.props.data.currentYearSeasonDates
    const currentTime = new Date().getTime()
    // Four days BEFORE season starts
    const oneDayPreviousSpring = new Date(new Date(previousSpring).setDate(new Date(previousSpring).getDate() - 4))
    const oneDaySummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 4))
    const oneDayAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 4))
    const oneDayWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 4))
    const oneDaySpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 4))
    const oneDayNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 4))

    // Create BEFORE the season dates. Should be rewind by 3 weeks.
    const beforeSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 19))
    const beforeAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 19))
    const beforeWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 19))
    const beforeSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 19))
    const beforeNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 19))

    // Logic -> If the current time is between the CLOSE registration date of previous season AND CLOSE registation date of next season
    // -> display information about the next season
    if (oneDayPreviousSpring < currentTime && currentTime <= oneDaySummer) {
      this.setState({
        nextSeasonText: "Summer",
        nextSeasonOpenDate: new Date(beforeSummer),
        nextSeasonCloseDate: new Date(oneDaySummer),
      })
    } else if (oneDaySummer < currentTime && currentTime <= oneDayAutumn) {
      this.setState({
        nextSeasonText: "Autumn",
        nextSeasonOpenDate: new Date(beforeAutumn),
        nextSeasonCloseDate: new Date(oneDayAutumn),
      })
    } else if (oneDayAutumn < currentTime && currentTime <= oneDayWinter) {
      this.setState({
        nextSeasonText: "Winter",
        nextSeasonOpenDate: new Date(beforeWinter),
        nextSeasonCloseDate: new Date(oneDayWinter),
      })
    } else if (oneDayWinter < currentTime && currentTime <= oneDaySpring) {
      this.setState({
        nextSeasonText: "Spring",
        nextSeasonOpenDate: new Date(beforeSpring),
        nextSeasonCloseDate: new Date(oneDaySpring),
      })
    } else if (oneDaySpring < currentTime && currentTime <= oneDayNextSummer) {
      this.setState({
        nextSeasonText: "Summer",
        nextSeasonOpenDate: new Date(beforeNextSummer),
        nextSeasonCloseDate: new Date(oneDayNextSummer),
      })
    }
  }

  render() {
    const { nextSeasonText, nextSeasonOpenDate, nextSeasonCloseDate } = this.state

    const formatDate = (date) => {
      const dateString = date.toDateString().split(" ")
      const theDay = dateString.slice(2, 3)[0]
      const theMonth = dateString.slice(1, 2)[0]
      const theYear = dateString.slice(3, 4)[0]

      return `${theDay} ${theMonth} ${theYear}`
    }

    return (
      <Fragment>
        {nextSeasonText !== "" && (
          <Fragment>
            {this.props.type === "header" ? (
              <Fragment>
                <div className="season-text-wrap">
                  <h3>{nextSeasonText}</h3>
                </div>

                <div className="info">
                  <p className="title">Opens on</p>
                  <p className="date season">{formatDate(nextSeasonOpenDate)}</p>
                </div>
                <div className="info">
                  <p className="title">Closes on</p>
                  <p className="date season">{formatDate(nextSeasonCloseDate)}</p>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="season-text-wrap">
                  <h3>We have 4 seasons across a year!</h3>
                </div>

                <div className="info">
                  <p className="title">Next:</p>
                  <p className="season">{nextSeasonText} Season</p>
                </div>

                <div className="info">
                  <p className="title">Period:</p>
                  <div className="date">
                    <p className="detail">{formatDate(nextSeasonOpenDate)}</p>
                    <p className="wave">~</p>
                    <p className="detail">{formatDate(nextSeasonCloseDate)}</p>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export { SeasonDates }
export default Header
