import { gql } from "@apollo/client"

/******************* Query current year season dates ******************/
export const CURRENT_YEAR_SEASON_DATES = gql`
  query {
    currentYearSeasonDates {
      id
      year
      previousSpring
      summer
      autumn
      winter
      spring
      nextSummer
    }
  }
`
