import styled from "styled-components"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export const MaxWidth = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`

export const LinkForBtn = styled(OutboundLink)`
  text-decoration: none;
  transition: all 0.2s ease-in-out;
`

export const LinkForNav = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};
  transition: all 0.2s ease-in-out;
  padding: 0.3rem 0.5rem;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkGreen};
    border-radius: 5px;
  }
`
export const LinkForNavMenu = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  transition: all 0.2s ease-in-out;
  padding: 0.5rem;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.red};
    border: 2px solid ${(props) => props.theme.colors.red};
    box-shadow: 1px 1px 0px ${(props) => props.theme.colors.red};
    border-radius: 5px;
  }
`

export const Button = styled.button`
  height: 3.2rem;
  font-size: 1.2rem;
  background-color: ${(props) => props.theme.colors.red};
  border: none;
  padding: 0 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid ${(props) => props.theme.colors.black};
    transform: translate(-7px, -7px);
    transition: all 0.2s ease-in-out;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.red};
    box-shadow: 1px 1px 0px ${(props) => props.theme.colors.red};
    color: ${(props) => props.theme.colors.red};
    background-color: ${(props) => props.theme.colors.white};

    &:before {
      display: none;
    }
  }

  @media only screen and (max-width: 860px) {
    padding: 0 1rem;
  }
`
