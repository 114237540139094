import React from "react"
import { FooterWrapper, Img } from "./style"
import MenuNav from "../Navigation/index"
import logoImage from "../../resources/images/logo-circle.png"
import Theme from "../../Themes/theme"
import { Button, LinkForNav, LinkForBtn } from "../Universal/style"
import { Link } from "gatsby"

const Footer = () => (
  <Theme>
    <FooterWrapper>
      <div className="footer-inner-wrapper">
        <div className="item-wrapper">
          <div className="footer-left">
            <Link to="/">
              <Img alt="site-logo" src={logoImage} />
            </Link>
          </div>
          <div className="footer-middle">
            <MenuNav />
            <div className="middle-email-wrap">
              <a href="mailto:support@flexitennisleagues.com">
                <i className="custom-icon icon-mail-alt">&#xf0e0;</i>
                <p className="email">support@flexitennisleagues.com</p>
              </a>
            </div>
          </div>
          <div className="footer-right">
            <LinkForBtn href={process.env.APP_URL_LOGIN} target="_blank" rel="noopener noreferrer">
              <Button>Login</Button>
            </LinkForBtn>
            <div className="socials-wrap">
              <a href="https://www.facebook.com/flexitennisleagues" target="_blank" rel="noreferrer">
                <i className="custom-icon icon-facebook-squared">&#xf308;</i>
              </a>
              <a href="http://www.instagram.com/flexitennisleagues" target="_target" rel="noreferrer">
                <i className="custom-icon icon-instagram">&#xf32d;</i>
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="bottom-inner-wrap">
            <div className="copyright">
              <p>{new Date().getFullYear()} © Flexi Tennis Leagues</p>
            </div>

            <div className="legal-links">
              <p>ABN 46 285 390 502</p>

              <span>|</span>
              <LinkForNav to="/terms" className="terms">
                Terms & Condition
              </LinkForNav>
              <span>|</span>

              <LinkForNav to="/privacy">Privacy policy</LinkForNav>
            </div>

            <div className="reference">
              <p>
                Website by <LinkForNav to="/">madebyHaT</LinkForNav>
              </p>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  </Theme>
)
export default Footer
