import styled from "styled-components"
import beltImage from "../../resources/images/belt-image.png"
import netImage from "../../resources/images/net-image.png"
import koalaGreen from "../../resources/images/koala-green-fill.png"

export const HeaderWrapper = styled.div`
  position: relative;
  margin-bottom: 8rem;
  height: 100%;

  &:before {
    content: "";
    background-image: url(${netImage});
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    left: 95%;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;

    @media only screen and (max-width: 560px) {
      display: none;
    }
  }

  &:after {
    content: "";
    background-image: url(${beltImage});
    background-repeat: repeat-x;
    background-size: 20rem;
    bottom: 0;
    left: 0;
    height: 72px;
    width: 100%;
    position: absolute;
    transform: translateY(5rem);

    @media only screen and (max-width: 900px) {
      height: 62px;
    }
    @media only screen and (max-width: 750px) {
      height: 55px;
    }
    @media only screen and (max-width: 500px) {
      height: 50px;
    }
  }

  .header-inner {
    background-color: ${(props) => props.theme.colors.darkGreen};
    color: ${(props) => props.theme.colors.white};
  }

  a {
    font-size: 1.2rem;
  }
  .nav-wrapper {
    border: 0.7rem solid ${(props) => props.theme.colors.white};
    border-bottom: none;

    .nav-inner {
      display: flex;
      align-items: center;
      border-bottom: none;
      height: 5rem;
      max-width: 1600px;
      margin: 0 auto;

      .desktop-menu {
        flex: 0 0 80%;

        ul {
          width: 100%;
          padding: 0 30% 0 10%;
        }
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .desktop-menu {
      display: none;
    }
    .hamburger-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      flex: 0 0 80%;
      padding-left: 5rem;

      #hamburger-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 35%;
        transition: all 0.2s ease-in-out;

        &:hover {
          cursor: pointer;

          span {
            background-color: ${(props) => props.theme.colors.red};
            transition: all 0.2s ease-in-out;
          }
        }

        span {
          width: 3rem;
          height: 0.3rem;
          background-color: ${(props) => props.theme.colors.white};
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: ${(props) => props.theme.colors.red};
          }
        }
      }
    }
    .mobile-menu {
      position: fixed;
      display: flex;
      flex-direction: column;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.white};
      z-index: 2;

      #close-menu {
        position: absolute;
        font-size: 1.2rem;
        width: 40px;
        height: 40px;
        top: 20px;
        right: 10px;
        border: 2px solid black;
        border-radius: 150px;
        transition: all 0.2s ease-in-out;
      }

      .mobile-ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin-top: 4rem;

        li {
          border-bottom: 1px dashed #ddd;
          width: 100%;
          padding-bottom: 1rem;
          margin: 0 auto 1.2rem;
          text-align: center;

          a {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .hamburger-wrapper {
      flex: 0 0 75%;
    }
  }

  .headermiddle-wrapper {
    display: flex;

    .header-left {
      flex: 0 0 70%;
      text-align: center;
      border: 0.7rem solid ${(props) => props.theme.colors.white};
      border-bottom: none;

      .logotext-wrapper {
        padding: 3.5rem 0;

        .tagline-wrapper {
          margin-top: 2rem;
        }
      }
    }
    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 30%;
      border: 0.7rem solid ${(props) => props.theme.colors.white};
      border-bottom: none;
      border-left: none;

      &:before {
        content: "";
        height: 0.7rem;
        width: 30%;
        top: 50%;
        position: absolute;
        background-color: ${(props) => props.theme.colors.white};
        z-index: 0;
      }

      .notice-wrapper {
        color: ${(props) => props.theme.colors.black};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        z-index: 1;

        .notice-inner {
          height: 100%;
          width: 70%;
          margin: 2rem 0 1rem;
        }

        .notice-body-wrap {
          height: 100%;
          width: 100%;
          background-color: ${(props) => props.theme.colors.white};
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;

          &:before {
            content: "Next Rego Info";
            color: ${(props) => props.theme.colors.red};
            font-size: 1.2rem;
            background-color: ${(props) => props.theme.colors.white};
            border: 2px solid ${(props) => props.theme.colors.red};
            box-shadow: 5px 5px 0 ${(props) => props.theme.colors.red};
            text-align: center;
            position: absolute;
            top: -15px;
            left: 30px;
            width: 65%;
            padding: 1rem;

            @media only screen and (max-width: 1215px) {
              left: 35px;
            }
            @media only screen and (max-width: 700px) {
              left: 30px;
            }
            @media only screen and (max-width: 600px) {
              left: 20px;
            }
          }

          &:after {
            content: "";
            background-image: url(${koalaGreen});
            background-repeat: no-repeat;
            background-size: contain;
            bottom: -27px;
            right: -20px;
            height: 30%;
            width: 25%;
            position: absolute;
          }

          .notice-body-inner {
            border: 2px dashed ${(props) => props.theme.colors.red};
            height: 85%;
            width: 90%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .season-text-wrap {
              margin-bottom: 1rem;

              > h3 {
                font-size: 1.7rem;
                text-decoration: underline;
              }
            }
            .info {
              margin-bottom: 1rem;

              .title {
                font-size: 1.2rem;
              }
              .date {
                color: ${(props) => props.theme.colors.red};
              }
            }
          }
        }
      }
      .mobile-notice-wrapper {
        display: none;
      }
    }

    @media only screen and (max-width: 1215px) {
      .header-left {
        flex: 0 0 60%;

        h1 {
          font-size: 2rem;
        }
      }
      .header-right {
        flex: 0 0 40%;

        &:before {
          width: 40%;
        }
      }
    }
    @media only screen and (max-width: 800px) {
      .header-left {
        flex: 0 0 50%;

        h1 {
          font-size: 2.5rem;
        }
      }
      .header-right {
        flex: 0 0 50%;

        &:before {
          width: 50%;
        }
        .notice-inner {
          width: 80% !important;

          h3 {
            font-size: 1.5rem !important;
          }
        }
      }
    }
    @media only screen and (max-width: 560px) {
      position: relative;
      flex-direction: column;

      &:before {
        display: none;
        position: absolute;
        content: "";
        width: 5px;
        height: 90.5%;
        top: 0;
        right: 70px;
        background-color: ${(props) => props.theme.colors.white};
        opacity: 0.2;
      }

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 9px;
        bottom: 63px;
        background-color: ${(props) => props.theme.colors.white};
      }

      .header-left,
      .header-right {
        flex: auto;
        width: 100%;
      }

      .header-left .logotext-wrapper {
        padding: 2rem 0;

        .tagline-wrapper {
          margin-top: 1rem;
        }
      }

      .header-right {
        border-left: 0.7rem solid ${(props) => props.theme.colors.white};
        padding: 4% 0;

        &:before {
          display: none;
        }

        .notice-inner {
          width: 90% !important;

          .notice-body-wrap {
            &:before {
              left: 13%;
            }

            .notice-body-inner {
              margin: 6rem 0 3rem;
            }
          }
        }

        .mobile-notice-wrapper {
          display: block;
        }
        .modal-wrap {
          position: fixed;
          z-index: 1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgb(0, 0, 0);
          background-color: rgba(0, 0, 0, 0.4);
          color: ${(props) => props.theme.colors.black};

          .modal-notice-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: ${(props) => props.theme.colors.black};
            background-color: ${(props) => props.theme.colors.white};
            height: 50%;
            z-index: 1;
            display: flex;
            margin: 15% auto;
            width: 80%;

            .modal-notice-inner {
              height: 95%;
              width: 70%;
            }

            .modal-notice-body-wrap {
              height: 100%;
              width: 100%;
              background-color: ${(props) => props.theme.colors.white};
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              position: relative;

              &:before {
                content: "Next Rego Info";
                color: ${(props) => props.theme.colors.red};
                font-size: 1.5rem;
                background-color: ${(props) => props.theme.colors.white};
                border: 2px solid ${(props) => props.theme.colors.red};
                box-shadow: 5px 5px 0 ${(props) => props.theme.colors.red};
                text-align: center;
                position: absolute;
                top: -10px;
                width: 50%;
                padding: 1rem;
              }

              .modal-notice-body-inner {
                border: 2px dashed ${(props) => props.theme.colors.red};
                height: 90%;
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .modal-season-text-wrap {
                  margin-bottom: 1rem;

                  > h3 {
                    font-size: 2rem;
                    text-decoration: underline;
                  }
                }
                .modal-info {
                  margin-bottom: 2rem;

                  .modal-title {
                    font-size: 1.2rem;
                  }
                  .dmodal-ate {
                    color: ${(props) => props.theme.colors.red};
                  }
                }
              }
            }
          }
        }
      }
    }
    .header-bottom {
      border: 0.7rem solid ${(props) => props.theme.colors.white};
      height: 5rem;
    }
  }
`

export const Img = styled.img`
  width: 10rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 760px) {
    width: 9rem;
  }
  @media only screen and (max-width: 700px) {
    width: 8rem;
  }
`
export const ImgKoala = styled.img`
  width: 13rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    opacity: 0.9;
    cursor: pointer;
  }
`
