import React, { Fragment } from "react"
import Header from "../Header/index"
import Footer from "../Footer/index"
import { MaxWidth } from "../Universal/style"

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <MaxWidth>{children}</MaxWidth>
      <Footer />
    </Fragment>
  )
}

export default Layout
