import styled from "styled-components"
import beltImage from "../../resources/images/belt-image.png"

export const FooterWrapper = styled.div`
  background: ${(props) => props.theme.colors.darkGreen};
  margin-top: 10rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    background-image: url(${beltImage});
    background-repeat: repeat-x;
    background-size: 20rem;
    top: 0;
    left: 0;
    height: 55px;
    width: 100%;
    position: absolute;
    transform: translateY(-3.8rem);
  }

  .footer-inner-wrapper {
    padding: 5% 0;
    width: 80%;

    .item-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      .footer-left {
        flex: 0 0 20%;
      }

      .footer-middle {
        flex: 0 0 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .middle-email-wrap {
          > a {
            text-decoration: none;
            color: ${(props) => props.theme.colors.white};
            display: flex;
            width: fit-content;
            padding: 5px;
            transition: all 0.2s ease-in-out;
            padding: 0.3rem 0.5rem;

            &:hover {
              color: ${(props) => props.theme.colors.darkGreen};
              background: ${(props) => props.theme.colors.white};
              border-radius: 5px;
              width: fit-content;
            }

            .icon-mail-alt {
              padding-right: 1rem;
            }
          }
        }
      }
      .footer-right {
        flex: 0 0 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .socials-wrap {
          i {
            color: ${(props) => props.theme.colors.white};
            padding-right: 1rem;
            font-weight: 400;
            font-size: 2rem;
            line-height: 1.5;
            transition: all 0.2s ease-in-out;

            &:hover,
            &:focus {
              color: ${(props) => props.theme.colors.red};
              cursor: pointer;
            }
          }
        }
      }
    }

    .bottom-inner-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${(props) => props.theme.colors.white};

      p {
        font-size: 1.2rem;
        color: white;
        text-decoration: none;
      }

      .copyright {
        flex: 0 0 25%;
      }
      .legal-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 480px;

        a {
          border-bottom: 1px dashed white;
          padding: 0.3rem 0.5rem;
        }
      }
      .reference {
        flex: 0 0 25%;
        text-align: end;
      }
    }

    @media only screen and (max-width: 1290px) {
      .item-wrapper {
        justify-content: center;
        flex-wrap: wrap;

        .footer-left {
          flex: 0 0 50%;
          display: flex;
          justify-content: flex-end;
          padding-right: 2rem;
          margin-bottom: 1rem;

          a img {
            width: 9rem;
          }
        }
        .footer-middle {
          order: 3;
          flex: auto;
          width: 100%;

          ul {
            width: 100%;
            max-width: 570px;
            margin: 0 auto 3rem;
          }
          .middle-email-wrap {
            display: flex;
            justify-content: center;
          }
        }
        .footer-right {
          flex: 0 0 50%;
          justify-content: space-evenly;
          padding-left: 2rem;
        }
      }

      .bottom-footer .bottom-inner-wrap {
        flex-direction: column;

        .legal-links {
          flex: auto;
          order: -1;
          margin-bottom: 2rem;

          a {
            margin: 0 1.3rem;
          }

          @media only screen and (max-width: 545px) {
            flex-direction: column;

            span {
              display: none;
            }
          }
        }

        .copyright,
        .reference {
          flex: auto;
          width: 100%;
          text-align: center;
        }
        .copyright {
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  @media only screen and (max-width: 690px) {
    .bottom-inner-wrap {
      width: 100%;
    }
  }

  @media only screen and (max-width: 750px) {
    .footer-inner-wrapper {
      width: 100%;
    }
  }
`

export const Img = styled.img`
  width: 10rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`
