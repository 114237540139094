import React from "react"
import styled from "styled-components"
import { LinkForNav, LinkForNavMenu } from "../Universal/style"
import { master } from "../../resources"

const Nav = styled.nav`
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 85%;

    li {
      a {
        color: ${(props) => props.theme.colors.white};
        text-decoration: none;
        border-bottom: 1px dashed white;
        padding: 0.3rem 0.5rem;
      }
    }

    @media only screen and (max-width: 600px) {
      border-top: 1px solid gray;
      padding-top: 3rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      li {
        margin-bottom: 1rem;
      }
    }
  }
`

const MenuNav = () => {
  const linksArray = master.linksArray

  return (
    <Nav className="desktop-menu">
      <ul>
        {linksArray.map((object, linkIndex) => (
          <li key={linkIndex}>
            <LinkForNav to={object.link}>{object.text}</LinkForNav>
          </li>
        ))}

        <li>
          <LinkForNav to="/blogs">Blogs</LinkForNav>
        </li>
      </ul>
    </Nav>
  )
}

export const MobileMenu = ({ setMenuStatus }) => {
  const linksArray = master.linksArray

  return (
    <nav className="mobile-menu">
      <div id="close-menu" className="close-button" onClick={() => setMenuStatus(false)}>
        <i className="custom-icon icon-cancel">&#xe801;</i>
      </div>

      <ul className="mobile-ul">
        {linksArray.map((object, linkIndex) => (
          <li key={linkIndex}>
            <LinkForNavMenu to={object.link} onClick={() => setMenuStatus(false)}>
              {object.text}
            </LinkForNavMenu>
          </li>
        ))}

        <li>
          <LinkForNavMenu to="/blogs">Blogs</LinkForNavMenu>
        </li>
      </ul>
    </nav>
  )
}

export default MenuNav
