export const master = {
  linksArray: [
    {
      link: "/",
      text: "Home",
    },
    {
      link: "/#how-it-works-section",
      text: "How it works",
    },
    {
      link: "/#leagues-section",
      text: "Leagues",
    },
    {
      link: "/#what-is-ftl-section",
      text: "Who is FTL for",
    },
  ],
  faqInfo: [
    {
      tab: "FTL Basics",
      type: "basic",
      accordionInfo: [
        {
          question: `What's the difference between "Sign up" and "Register"? `,
          answer: `"Sign up" means creating your FTL account. "Register" means registering yourself for a season to play tennis games. You need to register yourself every season you wish to play for.`,
        },
        {
          question: `I tried to register myself for a season, but it's not available. Why?`,
          answer: `There are registration periods available for each season and that's the only time when you can register yourself for the respective season. Each registration period lasts for 2 weeks and closes 4 days before the actual season starts. `,
        },
        {
          question: `I am unsure if I can follow all of the scheduled matches. Am I still OK to play for a season?`,
          answer: `You can arrange to play any of your scheduled matches at any time within the first 9 weeks of a season. However, if you play your scheduled games prior to or on the week of schedule, you get 1 FTL point.`,
        },
        {
          question: `When does the season start?`,
          answer: `Season starts on the 27th of January for Summer, 27th of April for Autumn, 27th of July for Winter, and 26th of October for Spring. Registrations are open 3 weeks prior to the season start date.`,
        },
        {
          question: `How do I sign up and register?`,
          answer: `Simply head to the FTL app at app.flexitennisleagues.com.au and signup. Once you have confirmed your email, you’ll be able to register for either Singles or Doubles leagues inside the main dashboard.`,
        },
        {
          question: `I just started playing tennis. Is FTL still for me?`,
          answer: `Yes, FTL caters for all levels! You may want to try Newbie leagues to play with the players who are at the similar level.`,
        },
        {
          question: `How do I submit the scores?`,
          answer: `For every weekly fixture, you’ll be able to upload scores for that specific week. Submitted scores will be sent to the opponent for approval. Once approved, the scores will be live in the results section and will reflect your ranking in the leaderboard. In an event of injury or absence of a party, you can check the winning player without declaring the scores (if no scores were made). The opponent will then need to approve this.`,
        },
        {
          question: `How does the FTL point system work?`,
          answer: `2 points is allocated to the winner, 1 point for the loser, and 1 point if games are played prior to or on the week of schedule. Grand final winners receive 20 points.`,
        },
        {
          question: `How long does a season last for?`,
          answer: `A season lasts for 10 weeks in duration.`,
        },
        {
          question: `How can I delete my account?`,
          answer: `To delete an account, please send a request to support@flexitennisleagues.com from the email of the requested account for deletion.`,
        },
        {
          question: `Can I register for two different leagues with the same FTL account?`,
          answer: `You can only register to play for one league in a season per account.`,
        },
      ],
    },
    {
      tab: "Game Rules",
      type: "game",
      accordionInfo: [
        {
          question: `When and Where are matches played?`,
          answer: `FTL automatically generates the fixtures of play for each week. Simply liaise with the opponent on day and location that suit both parties to conduct the match.`,
        },
        {
          question: `Does FTL provide tennis courts to conduct matches?`,
          answer: `Please refer to your local council for free of use courts. FTL is NOT responsible for the acquisition of courts and all arrangements are made by players.`,
        },
        {
          question: `What are the game rules?`,
          answer: `Basic rules as stated on http://protennistips.net/tennis-rules/. Players can converse with each other the rules they would like to enforce. Whether they would like to play up to 3 or 5 sets.`,
        },
        {
          question: `What is the grand final and Who it's for?`,
          answer: `The top two players with the highest points accumulated will play in the finals. Finals fixture will be available at the start of week 10 (final week) after the catch up week.`,
        },
        {
          question: `How do I submit the scores?`,
          answer: `For every weekly fixture, you’ll be able to upload scores for that specific week. Submitted scores will be sent to the opponent for approval. Once approved, the scores will be live in the results section and will reflect your ranking in the leaderboard. In an event of injury or absence of a party, you can check the winning player without declaring the scores (if no scores were made). The opponent will then need to approve this.`,
        },
        {
          question: `I have injured myself after a season has started. What should I do?`,
          answer: `Please contact support@flexitennisleagues.com to request your withdrawal so that we can inform other players who are in the same league as you.`,
        },
      ],
    },
    {
      tab: "Payments",
      type: "payment",
      accordionInfo: [
        {
          question: `How much does it cost to play?`,
          answer: `In celebration of our launch, all registrations for the Doubles and Singles leagues are free. Registrations are normally $27.99.`,
        },
        {
          question: `Can I get a refund if I am unable to play?`,
          answer: `Once the season fixtures have been generated, strictly no refunds will be provided under any circumstances unless a player's league has insufficient player count, in which case the player can request to be put in an alternative league or request a refund. For more information, please see our Terms & Conditions. Please ensure you will be available to play when registering in a league.`,
        },
      ],
    },
  ],
}

export const ynMaster = {
  yesNoQuestionsArray: [
    {
      question: `I have played tennis games before including casual matches.`,
      no: 1,
      yes: 3,
    },
    {
      question: `I am capable of serving.`,
      no: 5,
      yes: 2,
    },
    {
      question: `I am able to hold rallies.`,
      no: 5,
      yes: 4,
    },
    {
      question: `I have played tennis for many years.`,
      no: 2,
      yes: 4,
    },
    {
      question: `I consider myself as professional or semi-professional level.`,
      no: 6,
      yes: 7,
    },
    {
      answer: `Newbie`,
      description: `You are just starting out and wanting to gain some experience.You are looking for light and fun matches to get social and develop your game.`,
    },
    {
      answer: `Fair`,
      description: `You have some experience and looking to work on your game. You are able to hold rallies and capable of serving.`,
    },
    {
      answer: `Ace`,
      description: `You are quite competitive and looking for a challenge. You have excellent skillsets acquired through years of experience. You want to meet other players with similar experience and skillsets.`,
    },
  ],
}
